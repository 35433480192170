<template>
  <div class="stat-card" :class="`card-${theme}`">
    <half-circle-spinner
      :animation-duration="1500"
      :size="50"
      color="#008ecf"
      class="spinner"
      v-if="loading"
    />
    <div class="error-message" v-else-if="!loading && !!error">
      <p>{{ error }}</p>
      <b-button variant="primary" size="sm" @click="$emit('retry')">
        retry
      </b-button>
    </div>
    <div class="content" v-else>
      <div class="card-head">
        <div class="icon">
          <img :src="icon" alt="" />
        </div>
        <div class="head">
          <slot name="head"></slot>
        </div>
      </div>
      <hr />
      <div class="card-foot">
        <slot name="foot"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import HalfCircleSpinner from 'epic-spinners/src/components/lib/HalfCircleSpinner'

export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    theme: {
      type: String,
      default: () => 'light'
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    error: {
      type: String,
      default: () => ''
    }
  },
  components: { HalfCircleSpinner }
}
</script>

<style lang="scss" scoped>
.stat-card {
  --icon-gap: 2.5rem;
  margin-top: var(--icon-gap);
  &.card-light {
    background-color: white;
    padding: 1rem;
    border-radius: 0.5rem;
  }
  &.card-dark {
    background-color: var(--info);
    color: white;
    padding: 1rem;
    border-radius: 0.5rem;
  }
  .card-head {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex-wrap: wrap;
    .icon {
      width: 100px;
      height: 100px;
      background-color: var(--primary);
      padding: 0.75rem;
      border-radius: 0.5rem;
      display: flex;
      margin-top: calc(var(--icon-gap) * -1);
      img {
        width: 80%;
        margin: auto;
      }
    }
    .head {
      flex: 1;
    }
  }
  .error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.25rem;
    p {
      margin: 0;
      color: red;
    }
  }
}
</style>
