<template>
  <div class="row">
    <div class="col-12 col-lg-6">
      <StatCard
        :icon="require(`@/assets/images/icon-waiting_time.svg`)"
        :loading="agentStatsLoading"
        :error="agentStatsErrorMessage"
        @retry="fetchAgentStats"
      >
        <template #head>
          <div class="d-flex flex-column align-items-center">
            <p class="m-0">
              {{ $t('agent-dashboard.total-calls') }}
            </p>
            <h3 class="m-0">
              {{ $t('agent-dashboard.calls', { count: agentStats.total }) }}
            </h3>
          </div>
        </template>
        <template #foot>
          <h5 class="m-0 text-center text-primary">
            {{ $t('agent-dashboard.total-calls-today') }}
          </h5>
        </template>
      </StatCard>
    </div>
    <div class="col-12 col-lg-6">
      <StatCard
        :icon="require(`@/assets/images/icon-waiting_time.svg`)"
        :loading="agentStatsLoading"
        :error="agentStatsErrorMessage"
        @retry="fetchAgentStats"
      >
        <template #head>
          <div class="d-flex flex-column align-items-center">
            <p class="m-0">{{ $t('agent-dashboard.total-answered-calls') }}</p>
            <h3 class="m-0">
              {{
                $t('agent-dashboard.calls', {
                  count: agentStats.totalAnsweredCount
                })
              }}
            </h3>
          </div>
        </template>
        <template #foot>
          <h5 class="m-0 text-center text-primary">
            {{ $t('agent-dashboard.total-answered-calls-today') }}
          </h5>
        </template>
      </StatCard>
    </div>
    <div class="col-12 col-lg-6">
      <StatCard
        :icon="require(`@/assets/images/icon-call_duration.svg`)"
        :loading="agentStatsLoading"
        :error="agentStatsErrorMessage"
        @retry="fetchAgentStats"
      >
        <template #head>
          <div class="d-flex flex-column align-items-center">
            <p class="m-0">
              {{ $t('agent-dashboard.total-answered-duration') }}
            </p>
            <h3 class="m-0">
              {{ totalAnsweredDuration }}
            </h3>
          </div>
        </template>
        <template #foot>
          <h5 class="m-0 text-center text-primary">
            {{ $t('agent-dashboard.total-answered-duration-today') }}
          </h5>
        </template>
      </StatCard>
    </div>
  </div>
</template>
<script>
import StatCard from '../../elements/StatCard.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    StatCard
  },
  computed: {
    ...mapGetters([
      'agentStats',
      'agentStatsLoading',
      'agentStatsErrorMessage'
    ]),
    totalAnsweredDuration() {
      const [hours, minutes, seconds] = this.agentStats.totalAnsweredDuration
      return this.$t('shared.time', { hours, minutes, seconds })
    }
  },
  methods: {
    ...mapActions(['fetchAgentStats'])
  },
  mounted() {
    this.fetchAgentStats()
  }
}
</script>
<style lang="scss" scoped></style>
