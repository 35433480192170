<template>
  <div class="agent-dashboard">
    <h3 class="m-0 mb-2">{{ $t('agent-dashboard.statistics') }}</h3>
    <AgentStats />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AgentStats from '../../components/dashboard/AgentStats.vue'

let interval

export default {
  components: { AgentStats },
  data() {
    return {}
  },
  methods: {
    ...mapActions(['fetchAgentStats']),
    async initialFetchDashboardStats(silent = false) {
      this.fetchAgentStats({ silent })
    },
    async fetchDashboardStats(silent = false) {
      this.fetchAgentStats({ silent })
    }
  },
  watch: {
    currentTenant() {
      this.initialFetchDashboardStats()
    }
  },
  mounted() {
    interval = setInterval(() => {
      this.fetchDashboardStats(true)
    }, 30000)
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(interval)
    next()
  },
  computed: {
    ...mapGetters(['currentTenant', 'agentStatsError'])
  }
}
</script>

<style lang="scss" scoped></style>
